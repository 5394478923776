import { Text, Heading } from "./..";
import React from "react";

export default function EcoFriendlyProductDescription({
  titleText = "Eco-Friendly Product",
  descriptionText = "We offer eco-friendly cleaning products as an option for our services. By choosing this option, you can ensure a clean home that is safe for children and pets while also being kind to the planet. ",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-[30%] sm:w-[100%] sm:justify-center sm:text-center lg:w-[100%] lg:justify-center items-center`}
    >
      <Heading
        size=""
        as="h2"
        className="font-bevietnampro text-[150%] font-bold text-teal-300_01 sm:text-[150%] lg:text-[200%]"
      >
        {titleText}
      </Heading>
      <Text
        size="textxl"
        as="p"
        className="w-[100%] font-roboto text-[100%] font-normal leading-[130%] text-black-900 lg:text-center sm:w-[28%] md:w-[65%]"
      >
        {descriptionText}
      </Text>
    </div>
  );
}
