// import { Heading, Button, TextArea, Input, Text } from "../../components";
// import ContactInfo from "../../components/ContactInfo";
// import ReCAPTCHA from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
// import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
// import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";

// export default function Home1Rowcontactinfo({
//   headingText = "Email Now",
//   phoneNumberText = "contact@520cleaningsolutions.com",
// }) {
//   const [name, setName] = useState("");
//   const [message, setMessage] = useState("");
//   const [email, setEmail] = useState("");
//   const [disableSubmit, setDisableSubmit] = useState(true);
//   const [captchaChecked, setCaptchaChecked] = useState(false);

//   const recaptchaRef = React.useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();
//     if (name === "" || email === "" || message === "") {
//       alert("Preencha todos os campos");
//       return;
//     }
//     emailjs.init({
//       publicKey: "YOUR_PUBLIC_KEY",
//       // Do not allow headless browsers
//       blockHeadless: true,
//       blockList: {
//         // Block the suspended emails
//         list: ["foo@emailjs.com", "bar@emailjs.com"],
//         // The variable contains the email address
//         watchVariable: "userEmail",
//       },
//       limitRate: {
//         // Set the limit rate for the application
//         id: "app",
//         // Allow 1 request per 10s
//         throttle: 10000,
//       },
//     });

//     emailjs.send(
//       "service_8ovqbaj",
//       "template_7476w6x",
//       {
//         from_name: name,
//         message: message,
//         email: email,
//       },
//       "y4V8jVNy0PQ9aRaQn"
//     );

//     alert(`Hello ${name}, your email was sent successfully`);
//   };

//   useEffect(() => {
//     if (captchaChecked && name !== "" && email !== "" && message !== "") {
//       setDisableSubmit(false);
//     }
//   }, [captchaChecked, name, email, message]);

//   return (
//     <div className="container-xs w-[100%] flex sm:self-stretch  items-start justify-start gap-8  sm:flex-col sm:px-5 box-border flex-row lg:mt-20  lg:w-[80%] mt-0 md:mt-0 lg:ml-[5%] sm:mr-0 sm:ml-0 sm:items-center sm:justify-center sm:w-[100%] sm:gap-0  sm:mt-28">
//       <form
//         onSubmit={sendEmail}
//         className="contactForm mb-[54px] flex  flex-col items-end gap-[46px] w-[50%] justify-start sm:w-[100%]  sm:text-center sm:items-center sm:justify-center sm:mb-0"
//       >
//         <div className="flex w-[86%] flex-col items-start gap-2.5 sm:w-full justify-start  sm:text-center sm:items-center sm:justify-center">
//           <div className="flex flex-col items-start gap-1 sm:w-[100%] sm:text-center sm:justify-center sm:items-center ">
//             <Text
//               size="textsm"
//               as="p"
//               className="sm:flex font-bevietnampro text-[18.43px] font-normal text-gray-900 sm:text-center sm:justify-center"
//             >
//               Contact info
//             </Text>
//             <Heading
//               size="heading4xl"
//               as="h2"
//               className="font-bevietnampro text-[41.47px] font-semibold text-gray-900 sm:text-[37px] sm:text-[31px] sm"
//             >
//               <span>Keep In&nbsp;</span>
//               <span>Touch</span>
//             </Heading>
//           </div>
//           <Text
//             size="textsm"
//             as="p"
//             className="font-bevietnampro text-[18.43px] font-normal leading-[150%] tracking-[-0.18px] text-gray-700"
//           >
//             <>
//               We prioritize responding to your inquiries promptly to ensure you
//               receive the assistance you need in a timely manner
//             </>
//           </Text>
//         </div>
//         <div className="flex w-[86%] flex-col items-start justify-center gap-[34px] sm:w-full sm:items-center">
//           <div className="flex flex-col gap-[34px] self-stretch sm:gap-2">
//             <Input
//               type="text"
//               name="text"
//               placeholder={`Name`}
//               className="flex h-[64px] items-center justify-center rounded  border-solid border-gray-100_01 bg-white-a700 px-[22px] font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
//               onChange={(e) => setName(e.target.value)}
//               value={name}
//               required
//             />
//             <Input
//               type="email"
//               name="user_email"
//               placeholder={`Email`}
//               className="flex h-[64px] items-center justify-center rounded border-none outline-none bg-white-a700 px-[22px] font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
//               onChange={(e) => setEmail(e.target.value)}
//               value={email}
//               required
//             />
//             <Input
//               type="textarea"
//               name="message"
//               placeholder={`Message`}
//               className="h-[100px] rounded border-[1.15px] border-solid border-gray-100_01 bg-white-a700 px-[22px] py-3 font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
//               onChange={(e) => setMessage(e.target.value)}
//               value={message}
//               required
//             />
//           </div>
//           <ReCAPTCHA
//             sitekey="6LcsNncqAAAAALXB2tAfvZlYA8yW4whJuqydqJkT"
//             onChange={() => setCaptchaChecked(true)}
//           />
//           {disableSubmit ? (
//             <button
//               type="submit"
//               value="Send"
//               disabled={disableSubmit}
//               className={`${
//                 disableSubmit ? "" : "sentEmailEnabled"
//               }flex h-[48px] min-w-[178px] flex-row items-center justify-center  bg-red-600 px-[26px] text-center font-graphik text-[18.43px] font-medium text-white-a700 sm:px-5 sm:w-[90%] rounded-md`}
//             >
//               Sent Massage
//             </button>
//           ) : (
//             <button
//               type="submit"
//               value="Send"
//               disabled={disableSubmit}
//               className={`${
//                 disableSubmit ? "" : "sentEmailEnabled"
//               }flex h-[48px] min-w-[178px] flex-row items-center justify-center rounded-sm bg-teal-300 px-[26px] text-center font-graphik text-[18.43px] font-medium text-white-a700 sm:px-5 sm:w-[90%] rounded-md`}
//             >
//               Sent Massage
//             </button>
//           )}
//           ,
//         </div>
//       </form>
//       <div className="flex w-[46%] flex-col items-start gap-10 self-center p-2.5 sm:w-full sm:self-auto">
//         <Heading
//           size="text13xl"
//           as="h3"
//           className="font-bevietnampro text-[40px] font-medium text-gray-900 sm:text-[38px] sm:text-[36px]"
//         >
//           Find us
//         </Heading>
//         <div className="mb-[130px] mr-[54px] flex flex-col gap-[30px] self-stretch sm:mr-0 sm:mb-0 ">
//           <a href="tel:+18574962302" target="blank">
//             <ContactInfo />
//           </a>

//           <a
//             class=""
//             href="mailto:contact@520cleaningsolutions.com"
//             // href="mailto:pedroaugmeddev@gmail.com"
//             target="blank"
//           >
//             <div className="flex flex-col EmailInfo w-[100%] p-5 bg-[#fbfbfb] rounded-xl border border-[#f3f3f3] justify-start items-start gap-3 ">
//               <div className="EmailIcon shadow justify-center items-center gap-2.5 flex">
//                 <div className="flex Ellipse1406 w-[54px] h-[54px] bg-[#539b9b] rounded-full justify-center items-center">
//                   <img src="/images/emailVector.svg" className="w-[60%]" />
//                 </div>
//                 <h3 className="font-bevietnampro text-[20px] font-semibold tracking-[0.60px] text-gray-900">
//                   {headingText}
//                 </h3>
//               </div>
//               {/* <div className="EmailId w-[423px] flex-col justify-start items-start gap-2 ">
//                 <div className="EmailNow w-[423px] h-[22px] text-[#111c15] text-xl font-semibold font-['Be Vietnam Pro'] tracking-wide">
//                   Email Now
//                 </div>
//                 <div className="Contact520cleaningsolutionsCom  text-[#666666] text-lg font-normal font-['DM Sans'] leading-snug text-wrap flex flex-wrap w-[100%] max-w-[100%]">
//                   <p className="text-wrap w-[20%] max-w-[50%]">
//                     contact@520cleaningsolutions.com
//                   </p>
//                 </div>
//               </div> */}
//               <div className="flex flex-1 flex-col items-start ">
//                 {/* <Heading
//                   size="headings"
//                   as="h5"
//                   className="font-bevietnampro text-[20px] font-semibold tracking-[0.60px] text-gray-900"
//                 >
//                   {headingText}
//                 </Heading>
//                 <span
//                   as="p"
//                   className="inline text-[16px] font-normal text-gray-700 w-[50%] text-wrap max-w-1 w-[10%]"
//                 >
//                   {phoneNumberText}
//                 </span> */}
//                 <article className="text-wrap ">
//                   <p className="text-[16px] break-words  text-gray-700">
//                     {phoneNumberText}
//                   </p>
//                 </article>
//               </div>
//             </div>
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// }

import { Heading, Button, TextArea, Input, Text } from "../../components";
import ContactInfo from "../../components/ContactInfo";
import EmailInfo from "../../components/EmailInfo";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";

export default function Home1Rowcontactinfo({
  headingText = "Email Now",
  phoneNumberText = "contact@520cleaningsolutions.com",
}) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const recaptchaRef = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      alert("Preencha todos os campos");
      return;
    }
    emailjs.init({
      publicKey: "YOUR_PUBLIC_KEY",
      // Do not allow headless browsers
      blockHeadless: true,
      blockList: {
        // Block the suspended emails
        list: ["foo@emailjs.com", "bar@emailjs.com"],
        // The variable contains the email address
        watchVariable: "userEmail",
      },
      limitRate: {
        // Set the limit rate for the application
        id: "app",
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });

    emailjs.send(
      "service_8ovqbaj",
      "template_7476w6x",
      {
        from_name: name,
        message: message,
        email: email,
      },
      "y4V8jVNy0PQ9aRaQn"
    );

    alert(`Hello ${name}, your email was sent successfully`);
  };

  useEffect(() => {
    if (captchaChecked && name !== "" && email !== "" && message !== "") {
      setDisableSubmit(false);
    }
  }, [captchaChecked, name, email, message]);

  return (
    <div className="container-xs w-[100%] flex sm:self-stretch  items-start justify-start gap-8  sm:flex-col  box-border flex-row lg:mt-20  lg:w-[80%] mt-0 md:mt-0 lg:ml-[5%] sm:mr-0 sm:ml-0 sm:items-center sm:justify-center sm:w-[100%] sm:gap-0  sm:mt-28 px-0 sm:px-0">
      <form
        onSubmit={sendEmail}
        className="contactForm mb-[54px] flex  flex-col items-end gap-[46px] w-[50%] justify-start sm:w-[100%]  sm:text-center sm:items-center sm:justify-center sm:mb-0"
      >
        <div className="flex w-[86%] flex-col items-start gap-2.5 sm:w-full justify-start  sm:text-center sm:items-center sm:justify-center">
          <div className="flex flex-col items-start gap-1 sm:w-[100%] sm:text-center sm:justify-center sm:items-center ">
            <Text
              size="textsm"
              as="p"
              className="sm:flex font-bevietnampro text-[18.43px] font-normal text-gray-900 sm:text-center sm:justify-center"
            >
              Contact info
            </Text>
            <Heading
              size="heading4xl"
              as="h2"
              className="font-bevietnampro text-[41.47px] font-semibold text-gray-900 sm:text-[37px] sm:text-[31px] sm"
            >
              <span>Keep In&nbsp;</span>
              <span>Touch</span>
            </Heading>
          </div>
          <Text
            size="textsm"
            as="p"
            className="font-bevietnampro text-[18.43px] font-normal leading-[150%] tracking-[-0.18px] text-gray-700"
          >
            <>
              We prioritize responding to your inquiries promptly to ensure you
              receive the assistance you need in a timely manner
            </>
          </Text>
        </div>
        <div className="flex w-[86%] flex-col items-start justify-center gap-[34px] sm:w-[90%] sm:items-center">
          <div className="flex flex-col gap-[34px] self-stretch sm:gap-2">
            <Input
              type="text"
              name="text"
              placeholder={`Name`}
              className="flex h-[64px] items-center justify-center rounded  border-solid border-gray-100_01 bg-white-a700 px-[22px] font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
            <Input
              type="email"
              name="user_email"
              placeholder={`Email`}
              className="flex h-[64px] items-center justify-center rounded border-none outline-none bg-white-a700 px-[22px] font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
            <Input
              type="textarea"
              name="message"
              placeholder={`Message`}
              className="h-[100px] rounded border-[1.15px] border-solid border-gray-100_01 bg-white-a700 px-[22px] py-3 font-bevietnampro text-[18.43px] text-gray-700 sm:px-5"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
            />
          </div>
          <ReCAPTCHA
            sitekey="6LcsNncqAAAAALXB2tAfvZlYA8yW4whJuqydqJkT"
            onChange={() => setCaptchaChecked(true)}
          />
          {disableSubmit ? (
            <button
              type="submit"
              value="Send"
              disabled={disableSubmit}
              className={`${
                disableSubmit ? "" : "sentEmailEnabled"
              }flex h-[48px] min-w-[178px] flex-row items-center justify-center  bg-red-600 px-[26px] text-center font-graphik text-[18.43px] font-medium text-white-a700 sm:px-5 sm:w-[90%] rounded-md`}
            >
              Sent Massage
            </button>
          ) : (
            <button
              type="submit"
              value="Send"
              disabled={disableSubmit}
              className={`${
                disableSubmit ? "" : "sentEmailEnabled"
              }flex h-[48px] min-w-[178px] flex-row items-center justify-center rounded-sm bg-teal-300 px-[26px] text-center font-graphik text-[18.43px] font-medium text-white-a700 sm:px-5 sm:w-[90%] rounded-md`}
            >
              Sent Massage
            </button>
          )}
          ,
        </div>
      </form>
      <div className="flex w-[46%] flex-col items-start gap-10 self-center p-2.5 sm:w-full sm:self-auto">
        <Heading
          size="text13xl"
          as="h3"
          className="font-bevietnampro text-[40px] font-medium text-gray-900 sm:text-[38px] sm:text-[36px]"
        >
          Find us
        </Heading>
        <div className="mb-[130px] mr-[54px] flex flex-col gap-[30px] self-stretch sm:mr-0 sm:mb-0 ">
          <a href="tel:+18574962302" target="blank">
            <ContactInfo />
          </a>

          <a
            class=""
            href="mailto:contact@520cleaningsolutions.com"
            // href="mailto:pedroaugmeddev@gmail.com"
            target="blank"
          >
            <EmailInfo />
          </a>
        </div>
      </div>
    </div>
  );
}
