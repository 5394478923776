import React from "react";
import { Img } from "../../../components";
import SecondImg from "./flipcard/img_ellipse_7.png";
import FirstImg from "./flipcard/img_ellipse_8.svg";
export const SwapperImgOnHover = () => {
  return (
    <>
      <div className="thefront flex justify-center items-center self-center self-center w-[10%]">
        <Img src={FirstImg} className=" w-[60%]" />
      </div>
      <div className="theback flex justify-center items-center self-center self-center">
        <Img
          src={SecondImg}
          className="flex w-[60%] flex justify-center items-center self-center self-center w-[60%]"
        />
      </div>
    </>
  );
};
