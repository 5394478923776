const Scroll = ({ side, images, speed = 5000 }) => {
  return (
    <div
      class={`stock-ticker-${side} mt-[152px] mt h-[104px] self-stretch bg-[url(/public/images/img_group_379.png)] bg-cover bg-no-repeat py-[26px] md:h-auto sm:py-5 sm:mt-10`}
    >
      <ul aria-hidden="true">
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>

        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20 w-[100%]">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider ">Professional Service</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Trustworthy Team</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Reliable Results</h1>
          </li>
        </div>
        <div className="flex flex-row w-[100%]">
          <li class=" textSlider gap-20">
            <h1 class="w-[100%] mx-10 mb-0 h1Slider">Customer Focused</h1>
          </li>
        </div>
      </ul>
    </div>
  );
};

export { Scroll };
