import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Footer from "../../components/Footer";
import HeaderHome from "../../components/HeaderHome";
import AboutusAboutUs from "./AboutusAboutUs";
import AboutusColumndesignsem from "./AboutusColumndesignsem";
import React from "react";

export default function AboutusPage() {
  return (
    <>
      <div className="w-[100%] bg-gray-200 overflow-x-none container self-stretch">
        <div className="flex flex-col items-center overflow-x-none self-stretch w-full">
          <div className="flex w-[100%] bottom-0 left-0 right-0 top-0  flex h-max flex-1 flex-col items-center">
            <HeaderHome />
            <AboutusColumndesignsem />
            <AboutusAboutUs />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
