import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";
import { Text, Img, Slider, Button, Heading } from "../../components";
import React, { useEffect } from "react";

export default function Home1ColumnFifteen() {
  const [sliderState, setSliderState] = React.useState(1);
  const sliderRef = React.useRef(sliderState);
  useEffect(() => {}, [sliderState, setSliderState]);
  const items = [
    <div
      className={`flex items-center justify-center  flex-row sm:flex-col  md:flex-col md:items-center md:justify-center sm:items-start sm:ml-20`}
    >
      <div className="flex-1 ">
        <div className="flex items-center gap-5 ">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Boston{" "}
          </Text>
        </div>
        <div className="mt-1 flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Brookline
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Needham
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Dedham
          </Text>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mb-2 h-[36px] "
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Milton
          </Text>
        </div>
        <div className="mt-2.5 sm:mt-0 flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin Eleven"
            className="mt-1 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Quincy
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Braintree{" "}
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Randolph
          </Text>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-start justify-start pl-14 pr-[68px] md:items-center md:px-0 sm:self-auto  sm:items-center">
        <div className="flex-1 ">
          <div className="flex items-start justify-start start gap-5">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-2 h-[36px] sm:mt-0"
            />
            <Text
              size="text12xl"
              as="p"
              className="items-start justify-start text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Hingham
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1.5 sm:mt-0 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Cape Cod
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Raynham{" "}
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-2 h-[36px] sm:mt-0"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Plymouth
            </Text>
          </div>
        </div>
      </div>
    </div>,
    <div
      className={`flex items-center  flex-row sm:flex-col sm:justify-center md:flex-col md:justify-start md:items-start md:ml-10  sm:items-start sm: sm:ml-20 `}
    >
      <div className="flex-1 md:self-stretch md:items-center md:justify-center">
        <div className="flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%] text-nowrap mr-20 sm:text-wrap"
          >
            West Bridgewater{" "}
          </Text>
        </div>
        <div className="mt-1 flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            East Bridgewater
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Halifax
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Plympton
          </Text>
        </div>
      </div>
      <div className=" md:justify-start md:items-start">
        <div className="flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mb-2 h-[36px] self-end"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Duxbury
          </Text>
        </div>
        <div className="mt-2.5 sm:mt-0 flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin Eleven"
            className="mt-1 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Pembroke
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Hanson{" "}
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Kingston
          </Text>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-end self-start pl-14 pr-[68px] md:self-stretch md:px-5 sm:self-auto md:items-start md:px-0 sm:px-0">
        <div className=" sm:flex-1 ">
          <div className="flex items-start justify-start gap-5">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-2 h-[36px] sm:mt-0"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Hanover
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1.5 sm:mt-0 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Norwell
            </Text>
          </div>
        </div>
      </div>
    </div>,
    <div
      className={`" : ""
    } flex items-center  flex-row sm:flex-col sm:justify-center md:flex-col md:justify-start md:items-start md:ml-10 sm:ml-20 `}
    >
      <div className="flex-1 md:self-stretch md:items-center md:justify-center">
        <div className="flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%] text-nowrap mr-20"
          >
            Dover{" "}
          </Text>
        </div>
        <div className="mt-1 flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Westwood
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Canton
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Sharon
          </Text>
        </div>
      </div>
      <div className="flex-1 md:self-stretch md:items-center md:justify-center">
        <div className="flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%] text-nowrap mr-20"
          >
            Stoughton{" "}
          </Text>
        </div>
        <div className="mt-1 flex items-center gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Avon
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-1.5 sm:mt-0 h-[36px]"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Holbrook
          </Text>
        </div>
        <div className="flex items-start gap-5">
          <Img
            src="images/img_linkedin.svg"
            alt="Linkedin"
            className="mt-2 h-[36px] sm:mt-0"
          />
          <Text
            size="text12xl"
            as="p"
            className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
          >
            Weymouth
          </Text>
        </div>
      </div>
      <div className="flex flex-1 flex-col  self-start pl-14 pr-[68px] md:self-stretch md:px-5 sm:px-0 md:px-0">
        <div className="w-[84%] ">
          <div className="flex items-start justify-start gap-5">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-2 h-[36px] sm:mt-0"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Carver
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1.5 sm:mt-0 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Lakeville
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1.5 sm:mt-0 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[180%] sm:text-wrap"
            >
              Middleborough
            </Text>
          </div>
          <div className="flex items-start justify-start gap-[18px]">
            <Img
              src="images/img_linkedin.svg"
              alt="Linkedin"
              className="mt-1.5 sm:mt-0 h-[36px]"
            />
            <Text
              size="text12xl"
              as="p"
              className="self-center text-[39.93px] font-normal text-black-900 md:text-[120%] sm:text-[155%]"
            >
              Bridgewater
            </Text>
          </div>
        </div>
      </div>
    </div>,
  ];

  return (
    <div className="mt-24 flex flex-col items-center self-stretch px-14 md:px-5 sm:mt-0 sm:mb-0">
      <div className="mr-2 flex w-[90%] flex-col items-center gap-[30px] md:mr-0 sm:w-[100%]">
        <div className="flex justify-center items-center h-[910px] content-end self-stretch sm:h-auto sm:mt-10 ">
          <div className="flex bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 flex-col items-center">
            <div className="relative z-0 flex h-[824px] items-center justify-center self-stretch bg-[url(/public/images/bannerMap.png)]  bg-no-repeat py-[162px] md:h-auto md:py-5  w-[70%] lg:w-[100%]  lg:left-[10%] sm:left-0 sm:ht-[20%] sm:hidden ">
              <div className="container-xs mb-1 flex justify-center md:px-5 lg:ml-20 sm:ml-0 sm:hidden">
                <div className="relative h-[494px] w-full  z-50 lg:ml-64">
                  <div className="absolute bottom-[12.24px] left-0 right-0 m-auto w-[74%]">
                    <div className="flex flex-col items-end"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-xs relative mt-[-14px] flex flex-col items-start px-1.5 md:px-5 sm:w-[100%] sm:px-0">
              <Heading
                size="heading6xl"
                as="h2"
                className="mb-[132px] ml-[60px] lg:ml-0 font-bevietnampro text-[48.23px] font-bold capitalize text-black-900 md:ml-0 md:text-[44px]  sm:mb-2 sm:w-[100%] lg:mb-10 sm:text-center sm:text-3xl"
              >
                Free Estimate for Your Area
              </Heading>
              <Text
                size="text9xl"
                as="p"
                className="w-[80%] text-[33.03px] font-normal capitalize leading-[49px] text-gray-800_01  md:text-[31px]  sm:text-[155%]  sm:w-[100%] sm:text-center"
              >
                <span className="text-gray-800_01">At</span>
                <span className="text-black-900">&nbsp;</span>
                <span className="font-medium text-black-900">
                  520 Cleaning Solutions, we offer a free estimate for cleaning
                  services in your region.
                </span>
              </Text>
            </div>
          </div>
        </div>
        <div className="container-xs flex flex-col items-start gap-[74px] md:gap-[55px] md:px-5 sm:gap-[37px] sm:w-[100%] lg:mt-20 sm:mt-5 sm:px-0 sm:items-center">
          <Text
            size="text2xl"
            as="p"
            className="ml-1.5 text-[23.91px] font-normal capitalize leading-[35px] text-gray-700_01 md:ml-0 md:text-[21px] sm:m-0 sm:text-center"
          >
            <>
              {" "}
              If you are located in Massachusetts, contact us to get a no-cost
              evaluation,
              <br />
              and we will check if we service your specific area. <br />
              Our team is ready to provide exceptional and tailored cleaning
              solutions to meet your needs.
            </>
          </Text>
          <a href="https://forms.gle/LNWMEL59EvRHchmH8" target="blank">
            <Button className="flex h-[68px]  flex-row items-center justify-center rounded-[14px] bg-teal-300 px-6 text-center text-[33.03px] text-white-a700 md:text-[31px] sm:px-5 sm:text-[29px] growUpButton font-bevietnampro  sm:h-20 sm:text-nowrap">
              Get a quote today
            </Button>
          </a>

          <div className="mx-4 flex flex-col items-center gap-[52px] self-stretch md:mx-0 sm:gap-[26px] md:mb-[70%]">
            <Text
              size="text12xl"
              as="p"
              className="self-start text-[39.93px] font-normal italic text-black-900 md:text-[120%] sm:text-[100%] sm:text-center"
            >
              Some of the Places We Serve:
            </Text>
            <div className=" flex w-full h-52 md:flex-col md:w-[130%]">
              <Slider
                autoPlay={false}
                responsive={{
                  0: { items: 1 },
                  551: { items: 2 },
                  1051: { items: 1 },
                }}
                activeIndex={sliderState}
                ref={sliderRef}
                items={items}
                controlsStrategy="responsive"
                autoPlayInterval={5000}
                keyboardNavigation={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
