import React from "react";
import MegaMenu1 from "../MegaMenu1";
import { Img } from "./..";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Home from "../../pages/Home/index";

const NavigateMenu = ({ children, setOpenNavigate, ...restProps }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [move, setMove] = React.useState("");
  const [actualMove, setActualMove] = React.useState("");
  const [showServices, setShowServices] = useState(false);
  const navigate = useNavigate();

  const handleHoverEnter = ({ target }) => {
    setMove("up");
    setActualMove(target.id);
    target.id === "Services" && setShowServices(true);
  };

  const handleHoverLeave = ({ target }) => {
    setMove("down");
    setActualMove(target.id);
    target.id === "Services" && setShowServices(false);
  };

  function getPageYAfterScroll() {
    window.scrollTo(0, 0);
  }

  function handleChange(e) {
    e.preventDefault();
    // setSelectedOption(e.target.value);
  }

  function handleClick({}) {
    navigate(<Home goTestimonials={true} />);
  }
  return (
    <div className="absolute top-0 right-0 w-[55%]  flex-row flex-nowrap  justify-center   bg-gray-900_01 opacity-100   h-6  items-start sm:w-[40%]    sm:gap-2 sm:h-[28vh] rounded-b-xl        headerContent md:hidden sm:flex sm:flex-col sm:pl-[8%] sm:pr-[2%] xsm:min-h-[35vh]">
      <button
        onClick={() => {
          setOpenNavigate(false);
        }}
        className="absolute w-[18%] top-2 right-2"
      >
        <Img src="images/closeButton.svg" alt="Vector" className="w-[100%]" />
      </button>
      <div className="sm:absolute top-0  z-50 right-0 md:hidden  lg:hidden sm:h-[30vh] sm:w-[40%]">
        <button className="w-[30%]">
          <Img
            src="images/openNavigationButton.svg"
            alt="Vector"
            className="w-[100%]"
          />
        </button>
      </div>
      <ul className="flex flex-row  w-[50%]  justify-center sm:justify-center  flex-nowrap gap-5 sm:gap-1  md:gap-2 headerUl sm:flex-col  sm:items-start sm:pl-0 sm:pt-0">
        <h1 className="flex justify-center items-center ">
          {window.location.pathname === "/Home" ? (
            <a
              href="#"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Home" ? move : ""
              } bg-gradient bg-clip-text`}
            >
              <p
                size="textxs"
                as="p"
                id="Home"
                className="text-lg font-medium text-transparent hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[120%] text-[60%] md:text-[70%]  textPageLinks"
              >
                Home
              </p>
            </a>
          ) : (
            <a
              href="/Home"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Home" ? move : ""
              } bg-gradient bg-clip-text `}
            >
              <p
                size="textxs"
                as="p"
                id="Home"
                className="text-lg font-medium text-transparent hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[120%]  text-[60%] md:text-[70%]  textPageLinks "
              >
                Home
              </p>
            </a>
          )}
        </h1>
        <h1 className="flex justify-center items-center">
          <a
            href="/AboutUs"
            onMouseEnter={(e) => handleHoverEnter(e)}
            onMouseLeave={(e) => handleHoverLeave(e)}
            className={`${actualMove === "AboutUs" ? move : ""} cursor-pointer`}
          >
            <p
              size="textxs"
              as="p"
              id="AboutUs"
              className="text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center  flex-nowrap text-nowrap   sm:text-[100%]  text-[60%] md:text-[70%]  textPageLinks"
            >
              About us
            </p>
          </a>
        </h1>
        <h1 className="flex justify-center items-center ">
          {window.location.pathname === "/Home" ? (
            <a
              href="/#TestimonialsTarget"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Testimonials" ? move : ""
              } cursor-pointer`}
            >
              <p
                size="textxs"
                as="p"
                className="text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks"
              >
                Testimonials
              </p>
            </a>
          ) : (
            <button
              onClick={handleClick}
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Testimonials" ? move : ""
              } cursor-pointer`}
            >
              <p
                size="textxs"
                as="p"
                className="text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks"
              >
                Testimonials
              </p>
            </button>
          )}
        </h1>
        <h1
          className="flex justify-center items-center"
          onMouseLeave={() => {
            setMenuOpen(false);
          }}
          onMouseEnter={() => {
            setMenuOpen(true);
          }}
        >
          <div className="flex cursor-pointer items-center gap-2  sm:mr-1 md:mr-1">
            <p
              size="textxs"
              as="p"
              id="Services"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Services" ? move : ""
              } cursor-pointer text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline  items-center justify-center text-center sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks`}
            >
              Services
            </p>
            <Img
              src="images/img_arrow_down_white_a700.svg"
              alt="Arrowdown"
              className={`${actualMove === "Services" ? move : ""}  sm:mr-1`}
            />
          </div>
          <div className="flex self-start ">
            {menuOpen ? <MegaMenu1 /> : null}
          </div>
        </h1>
        <h1 className="flex justify-center items-center">
          <a href="/Contact" className="cursor-pointer">
            <p
              size="textxs"
              as="p"
              id="Contact"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Contact" ? move : ""
              } text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks`}
            >
              Contact
            </p>
          </a>
        </h1>
        <h1 className="flex justify-center items-center">
          <a href="JoinUs" className=" cursor-pointer">
            <p
              size="textxs"
              as="p"
              id="Join Us"
              onMouseEnter={(e) => handleHoverEnter(e)}
              onMouseLeave={(e) => handleHoverLeave(e)}
              className={`${
                actualMove === "Join Us" ? move : ""
              } text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center  flex-nowrap text-nowrap   sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks`}
            >
              Join Us
            </p>
          </a>
        </h1>
      </ul>
      <div className="flex items-center p-5  gap-0.5 sm:text-center sm:justify-center sm:gap-1 sm:w-[70%] sm:pr-10  freeEstimate py-0 sm:ml-[15%]">
        <Img
          src="images/img_symbol.png"
          alt="Symbol"
          className="h-[13px] z-0 md:h-[12px] sm:h-4 object-cover lg:h-5"
        />
        <a href="https://forms.gle/LNWMEL59EvRHchmH8" target="blank">
          <p
            size="textxs"
            as="p"
            className="bg-gradient bg-clip-text text-lg font-medium text-transparent flex flex-nowrap text-nowrap  sm:text-[100%] text-[60%] md:text-[70%]  textPageLinks cursor-pointer sm:text-center sm:items-center sm:justify0center"
          >
            FREE ESTIMATE
          </p>
        </a>
      </div>
    </div>
  );
};

export { NavigateMenu };
