import { Helmet } from "react-helmet";
import { Img, Text } from "../../components";

import Footer from "../../components/Footer";
import HeaderHome from "../../components/HeaderHome";
import Home1Rowcontactinfo from "../Home/Home1Rowcontactinfo.jsx";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import AboutusPage from "../../pages/Aboutus/index";

export default function Home1Page() {
  const [defaultDate, setDefaultDate] = useState("");
  const [action, setAction] = useState("");
  const [menuOpenTypes, setMenuOpenTypes] = useState(false);
  const [menuOpenFrequencys, setMenuOpenFrequencys] = useState(false);
  const navigate = useNavigate();

  let yourDate = new Date();
  let actualDate = yourDate;

  const handleHoverEnter = () => {
    setAction("enter");
  };

  const handleClick = (value) => {
    if (value === "FREQUENCY") {
      setMenuOpenFrequencys(!menuOpenFrequencys);
    } else {
      setMenuOpenTypes(!menuOpenTypes);
    }
  };

  const handleHoverLeave = () => {
    setAction("leave");
  };

  useEffect(() => {
    defaultDate == "" &&
      setDefaultDate(new Date().toISOString().substring(0, 16));
  }, [defaultDate]);

  const goToAboutUs = () => {
    navigate(<AboutusPage />);
  };

  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="w-full bg-gray-200 container ">
        <div className="flex flex-col items-center">
          <div className="relative h-[full] self-stretch">
            <div className="absolute left-0 right-[5%] top-[5%] m-auto flex flex-1 flex-col items-end w-[100%]">
              <Img
                src="images/img_3_1.png"
                alt="Image"
                className="relative sm:flex z-[2] h-[100%] w-[42%] object-contain sm:mt-32 mt-20 lg:mt-20 lg:w-[30%] mr-10 sm:w-[50%] sm:mr-2"
              />
              <div className="absolute left-0 right-0 top-0 m-auto flex flex-1 flex-col items-start w-[100%]">
                <HeaderHome />
                <div className="flex flex-col items-start self-stretch py-[22px] sm:py-5   md:w-[65%] lg:w-[120%] sm:w-[60%]  m-0 mt-20 sm:px-5 px-20   pl-14 md:gap-5 lg:gap-10  md:mt-28 lg:ml-[6%]sm:p-0">
                  <Text
                    size="text15xl"
                    as="p"
                    className="mt-1.5 w-[50%] font-dmserifdisplay font-normal leading-[64px] sm:leading-10 text-gray-900_01 md:w-full  text-[200%] md:mt-10 lg:mt-20 sm:mt-0 sm:text-4xl"
                  >
                    Contact info
                  </Text>

                  <Text
                    size="text4xl"
                    as="p"
                    className="w-[50%] text-[25.15px] font-normal leading-[37px] text-blue_gray-700_01 md:w-full md:text-[23px] md:gap-10 md:flex md:flex-col md:text-[150%]  lg:flex lg:flex-col lg:gap-12 sm:text-[100%]"
                  >
                    <h1>Send us a message or call to discuss your needs.</h1>
                    <h1>
                      {" "}
                      We respect your time and strive to respond quickly to all
                      inquiries.
                    </h1>
                  </Text>
                </div>
                <Home1Rowcontactinfo />
                <Footer />
              </div>{" "}
            </div>
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
}
// import React from "react";
// import Footer from "../../components/Footer";

// export default function index() {
//   return (
//     <div className="flex flex-col bg-[var(--OFF-WHITE, #EEE)] h-[2029px] w-[100%] self-stretch">
//       <HeaderHome />
//       <div className="bg-red-600 h-full m-0">
//         <div className="absolute bottom-px left-0 right-0 m-auto flex flex-1 flex-col items-end">
//           {" "}
//           <Img
//             src="images/img_3_1.png"
//             alt="Image"
//             className="relative z-[2] h-[936px] w-[42%] object-contain"
//           />
//         </div>
//         <Home1Rowcontactinfo />
//       </div>
//       <Footer />
//     </div>
//   );
// }
