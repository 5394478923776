import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Img } from "../";
import PortugueseLogo from "../Header/Icons/img_ellipse_1.png";
import SpanishLogo from "../LanguageIcons/SpanishIcon.svg";
import EnglishLogo from "../LanguageIcons/EnglishLogo.svg";

const ActualLanguage = ({
  children,
  className = "",
  Icon,
  selectedLanguage,
  setMenuLanguageIsOpen,
  ...restProps
}) => {
  function handleIcons() {
    if (selectedLanguage == "Portuguese") {
      return (
        <Img
          src={PortugueseLogo}
          className="flex justify-center items-center w-[18%] ml-2  sm:w-[25%] max-w-[16%]"
        />
      );
    } else if (selectedLanguage == "English") {
      return (
        <Img
          src={EnglishLogo}
          className="flex justify-center items-center w-[18%]  ml-2  sm:w-[25%]"
        />
      );
    } else {
      return (
        <Img
          src={SpanishLogo}
          className="flex justify-center items-center w-[18%] ml-2  sm:w-[25%]"
        />
      );
    }
  }

  return (
    <div
      onClick={() => {
        setMenuLanguageIsOpen(true);
      }}
      className="flex w-[100%] gap-0 justify-evenly items-center    bg-blue_gray-100 hover:bg-[#a8a4a4]  rounded-[18px] hover:cursor-pointer border-solid border-gray-900_01   md:w-full py-2    px-8 pl-2 sm:pl-0 gap-2 sm:py-1 sm:px-5 sm:pr-8 sm:gap-1 lg:py-3 buttonDiv opacity-100 max-h-12"
    >
      {handleIcons()}
      <h1
        className="flex m-0
font-medium
font-['DM
Sans']
leading-[14.99px] text-[10px] sm:text-[30%] lg:text-[100%] buttonH1"
      >
        {selectedLanguage}
      </h1>
    </div>
  );
};

ActualLanguage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  Icon: PropTypes.node,
  selectedLanguage: PropTypes.string,
};

export { ActualLanguage };
