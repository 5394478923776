import { element } from "prop-types";
import MegaMenu1 from "../MegaMenu1";
import { SelectBox, Img, p, Text } from "./..";
import React from "react";
import { useState, useEffect, useRef, actualP } from "react";
import PortugueseLogo from "./Icons/img_ellipse_1.png";
import MenuServices from "./../MenuServices/index";
import { NavigateMenu } from "./../NavigateMenu/NavigateMenu";
import { SelectLanguage } from "../../components/SelectLanguage";
import { languageOptions } from "../../tools/options";
import { MenuLanguage } from "../MenuLanguage/index";
import { ActualLanguage } from "../ActualLanguage/index";
import { useNavigate } from "react-router-dom";
import Home from "../../pages/Home/index";

export default function Header({ ...props }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [move, setMove] = React.useState("");
  const [actualMove, setActualMove] = React.useState("");
  const [showServices, setShowServices] = useState(false);
  const [menuLanguageIsOpen, setMenuLanguageIsOpen] = useState(false);
  const [openNavigate, setOpenNavigate] = useState();
  const [languageOptions, setLanguageOptions] = useState([
    "Portuguese",
    "English",
    "Espanõl",
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("Portuguese");

  const navigate = useNavigate();

  const handleHoverEnter = ({ target }) => {
    setMove("up");
    setActualMove(target.id);
    target.id === "Services" && setShowServices(true);
  };

  const handleHoverLeave = ({ target }) => {
    setMove("down");
    setActualMove(target.id);
    target.id === "Services" && setShowServices(false);
  };

  function getPageYAfterScroll() {
    window.scrollTo(0, 0);
  }

  function handleChange(e) {
    e.preventDefault();
    // setSelectedOption(e.target.value);
  }

  function handleLanguageButtons() {
    return menuLanguageIsOpen ? (
      <div className="flex shadow-none z-20 justify-center items-center w-[25%] sm:hidden ">
        <MenuLanguage
          Icon={PortugueseLogo}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          setMenuLanguageIsOpen={setMenuLanguageIsOpen}
        />
      </div>
    ) : (
      <div className="flex shadow-none z-20 justify-end items-center w-[25%] mt-0.5 sm:mt-0 sm:pr-2 sm:hidden">
        <ActualLanguage
          Icon={PortugueseLogo}
          selectedLanguage={selectedLanguage}
          setMenuLanguageIsOpen={setMenuLanguageIsOpen}
        />
      </div>
    );
  }

  function handleClick() {
    navigate(<Home goTestimonials={true} />);
  }

  return (
    <header
      {...props}
      className={`${props.className} flex flex-row w-[100%]   py-5  fixed bg-[#A8D5D54D] items-start z-50 top-0    justify-center  sm:gap-1   sm:px-1 sm:py-5 backdrop-blur-sm `}
    >
      <div className="flex flex-row w-[80%]  gap-8 md:gap-8  items-start justify-center   headerContentFull ml-4  sm:w-[100%] sm:justify-between sm:items-center sm:gap-0">
        <div className="flex  flex-row    items-center justify-center  p-0   w-[90%]   md:gap-2 gap-8 sm:gap-5 headerGroup sm:w-[50%]">
          <div
            onClick={() => {
              getPageYAfterScroll();
            }}
            className="flex    cursor-pointer  items-center px-4  rounded-[20px] border-[1.52px] border-solid border-gray-900_01  w-[25%] sm:w-[100%] md:gap-1 sm:gap-0.5   h-5 justify-start  pr-7 sm:py-4  sm:p-1  sm:h-5 test py-4 lg:py-4 lg:gap-1 groupSolutions opacity-100 sm:pr-6  sm:justify-center sm:items-center sm:pl-2"
          >
            <h1
              size="text:lg"
              as="p"
              className="font-bookantiqua text-[80%] font-normal italic tracking-[-1.80px] text-gray-900_01  w-[10%] lg:w-[15%] justify-center text-center items-center mr-2 sm:text-[100%] md:m-0 md:text-[70%] sm:m-0 sm:p-0 sm:mr-1 lg:text-[100%] x520"
            >
              520
            </h1>
            <div className="flex  flex-col items-end  sm:w-[45%]  md:w-[50%] vectorImg">
              <Img
                src="images/img_vector.svg"
                alt="Vector"
                className="mr-[30%] h-[3px] w-[4px]  "
              />
              <Img
                src="images/img_settings.svg"
                alt="Settings"
                className=" w-full "
              />
            </div>

            <h1
              size="text6xl"
              as="p"
              className="font-karla font-normal italic  text-gray-900_01 text-[80%]   w-[20%] text-center pr-10 justify-center  items-center sm:text-[110%]  sm:p-0 sm:pr-2 sm:w-[10%] md:text-[80%] sm:m-0  lg:text-[100%] solutions sm:mr-10"
            >
              Solutions
            </h1>
          </div>
          <div className="flex w-[85%]  flex-row flex-nowrap  justify-center  rounded-[24px] px-3 bg-gray-900_01 opacity-100  sm:p-0 sm:ml-2  h-6  md:h-8   items-center sm:w-[120%] sm:px-8  sm:gap-2 sm:py-0 sm:h-5 lg:py-5 lg:h-12 smd:w-[70%] headerContent sm:hidden">
            <ul className="flex flex-row  w-[100%]  justify-center sm:justify-center  flex-nowrap gap-5 sm:gap-1 sm:pl-3 pr-0 sm:py-0 py-2 md:gap-2 headerUl">
              <h1 className="flex justify-center items-center">
                {window.location.pathname === "/Home" ? (
                  <a
                    href="#"
                    onMouseEnter={(e) => handleHoverEnter(e)}
                    onMouseLeave={(e) => handleHoverLeave(e)}
                    className={`${
                      actualMove === "Home" ? move : ""
                    } bg-gradient bg-clip-text`}
                  >
                    <p
                      size="textxs"
                      as="p"
                      id="Home"
                      className="text-lg font-medium text-transparent hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[20%] text-[60%] md:text-[70%]  textPageLinks"
                    >
                      Home
                    </p>
                  </a>
                ) : (
                  <a
                    href="/Home"
                    onMouseEnter={(e) => handleHoverEnter(e)}
                    onMouseLeave={(e) => handleHoverLeave(e)}
                    className={`${
                      actualMove === "Home" ? move : ""
                    } bg-gradient bg-clip-text `}
                  >
                    <p
                      size="textxs"
                      as="p"
                      id="Home"
                      className="text-lg font-medium text-transparent hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[20%]  text-[60%] md:text-[70%]  textPageLinks "
                    >
                      Home
                    </p>
                  </a>
                )}
              </h1>
              <h1 className="flex justify-center items-center">
                <a
                  href="/AboutUs"
                  onMouseEnter={(e) => handleHoverEnter(e)}
                  onMouseLeave={(e) => handleHoverLeave(e)}
                  className={`${
                    actualMove === "AboutUs" ? move : ""
                  } cursor-pointer`}
                >
                  <p
                    size="textxs"
                    as="p"
                    id="AboutUs"
                    className="text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center  flex-nowrap text-nowrap   sm:text-[10%]  text-[60%] md:text-[70%]  textPageLinks"
                  >
                    About us
                  </p>
                </a>
              </h1>
              <h1 className="flex justify-center items-center sm:w-[20%]">
                <a
                  href="/#TestimonialsTarget"
                  onMouseEnter={(e) => handleHoverEnter(e)}
                  onMouseLeave={(e) => handleHoverLeave(e)}
                  className={`${
                    actualMove === "Testimonials" ? move : ""
                  } cursor-pointer`}
                >
                  <p
                    size="textxs"
                    as="p"
                    className="text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[10%] text-[60%] md:text-[70%]  textPageLinks"
                  >
                    Testimonials
                  </p>
                </a>
              </h1>
              <h1
                className="flex justify-center items-center"
                onMouseLeave={() => {
                  setMenuOpen(false);
                }}
                onMouseEnter={() => {
                  setMenuOpen(true);
                }}
              >
                <div className="flex cursor-pointer items-center gap-2 sm:gap-0 sm:mr-1 md:mr-1">
                  <p
                    size="textxs"
                    as="p"
                    id="Services"
                    onMouseEnter={(e) => handleHoverEnter(e)}
                    onMouseLeave={(e) => handleHoverLeave(e)}
                    className={`${
                      actualMove === "Services" ? move : ""
                    } cursor-pointer text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline  items-center justify-center text-center sm:text-[10%] text-[60%] md:text-[70%]  textPageLinks`}
                  >
                    Services
                  </p>
                  <Img
                    src="images/img_arrow_down_white_a700.svg"
                    alt="Arrowdown"
                    className={`${
                      actualMove === "Services" ? move : ""
                    } sm-[5%] sm:h-[5px] sm:mr-1`}
                  />
                </div>
                <div className="flex self-start ">
                  {menuOpen ? <MegaMenu1 /> : null}
                </div>
              </h1>
              <h1 className="flex justify-center items-center">
                <a href="/Contact" className="cursor-pointer">
                  <p
                    size="textxs"
                    as="p"
                    id="Contact"
                    onMouseEnter={(e) => handleHoverEnter(e)}
                    onMouseLeave={(e) => handleHoverLeave(e)}
                    className={`${
                      actualMove === "Contact" ? move : ""
                    } text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center sm:text-[10%] text-[60%] md:text-[70%]  textPageLinks`}
                  >
                    Contact
                  </p>
                </a>
              </h1>
              <h1 className="flex justify-center items-center">
                <a href="JoinUs" className=" cursor-pointer">
                  <p
                    size="textxs"
                    as="p"
                    id="Join Us"
                    onMouseEnter={(e) => handleHoverEnter(e)}
                    onMouseLeave={(e) => handleHoverLeave(e)}
                    className={`${
                      actualMove === "Join Us" ? move : ""
                    } text-lg font-medium text-white-a700 hover:text-orange-300 hover:underline items-center justify-center text-center  flex-nowrap text-nowrap   sm:text-[10%] text-[60%] md:text-[70%]  textPageLinks`}
                  >
                    Join Us
                  </p>
                </a>
              </h1>
            </ul>
            <div className="flex items-center p-5  gap-0.5 sm:text-center sm:justify-center sm:gap-1 sm:w-[35%] sm:pr-7 sm:ml-1 freeEstimate">
              <Img
                src="images/img_symbol.png"
                alt="Symbol"
                className="h-[13px] md:h-[12px] sm:h-2 object-cover lg:h-5"
              />
              <a href="https://forms.gle/LNWMEL59EvRHchmH8" target="blank">
                <p
                  size="textxs"
                  as="p"
                  className="bg-gradient bg-clip-text text-lg font-medium text-transparent flex flex-nowrap text-nowrap  sm:text-[10%] text-[60%] md:text-[70%]  textPageLinks cursor-pointer"
                >
                  FREE ESTIMATE
                </p>
              </a>
            </div>
          </div>
        </div>

        {handleLanguageButtons()}

        {openNavigate ? (
          <NavigateMenu setOpenNavigate={setOpenNavigate} />
        ) : (
          <div className="sm:flex md:hidden  lg:hidden justify-center items-center w-[25%] ">
            <button onClick={() => setOpenNavigate(true)} className="w-[30%]">
              <Img
                src="images/openNavigationButton.svg"
                alt="Vector"
                className="w-[100%]"
              />
            </button>
          </div>
        )}
      </div>
    </header>
  );
}
