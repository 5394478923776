import { Heading, Img } from "./..";
import React from "react";

export default function FeatureList({
  vettedText = "Vetted professionals",
  reliableText = "Reliable Results",
  standardText = "Standard cleaning tasks",
  isStandard = true,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center md:w-full gap-4 sm:pt-0 mt-0 sm:self-stretch sm:items-baseline sm:justify-between`}
    >
      <div className="flex items-center justify-around w-[110%] gap-3  rounded bg-gray-100 p-2 shadowText lg:h-16  h-[10vh] flex-nowrap self-stretch sm:gap-1 sm:self-stretch  sm:w-[100%] lg:h-16">
        <Img
          src="images/img_checkmark.svg"
          alt="Image"
          className="flex h-[22px] w-[20%]"
        />
        <Heading
          size="headingmd"
          as="h5"
          className="flex w-[100%] font-lato text-[80%] lg:w-[100%] lg:text-[120%] sm:text-[10px] font-semibold text-gray-900_0 md:text-[150%]  text-nowrap p-2 sm:text-wrap sm:self-stretch sm:w-[100%]"
        >
          {vettedText}
        </Heading>
      </div>
      <div className="flex w-[110%] sm:w-[100%] items-center justify-evenly gap-3  rounded bg-gray-100 px-2 py-2 self-stretch shadowText lg:h-16  h-[10vh]">
        <Img
          src="images/img_checkmark.svg"
          alt="Reliable"
          className="flex h-[22px] w-[20%]"
        />
        <Heading
          size="headingmd"
          as="h5"
          className="flex w-[80%] font-lato text-[80%] lg:w-[100%] lg:text-[120%] sm:text-[10px] font-semibold text-gray-900_0 md:text-[150%] "
        >
          {reliableText}
        </Heading>
      </div>
      {/* <div className="flex items-center justify-evenly gap-3 rounded bg-gray-100 p-2 py-2 self-stretch shadowText lg:h-16  h-[10vh] w-[110%] sm:w-[130%] sm:gap-0">
        <Img
          src="images/img_checkmark.svg"
          alt="Image"
          className="flex h-[22px] w-[20%] "
        />
        <Heading
          size="headingmd"
          as="h5"
          className="flex self-end font-lato text-[80%] lg:w-[100%] lg:text-[120%] font-semibold text-gray-900_0 sm:text-[10%]2 w-[80%] sm:text-wrap sm:text-center sm:gap-0 sm:w-[60%]"
        >
          {standardText}
        </Heading>
      </div> */}
      <div className="flex items-center sm:justify-center w-[110%] gap-3  rounded bg-gray-100 shadowText lg:h-16 sm:h-[10vh] flex-nowrap self-stretch sm:gap-1 p-2 sm:self-stretch sm:w-[100%] md:text-[150%] ">
        <Img
          src="images/img_checkmark.svg"
          alt="Image"
          className="flex h-[22px] w-[20%]"
        />
        <Heading
          size="headingmd"
          as="h5"
          className="flex w-[100%] font-lato text-[80%] lg:w-[100%] lg:text-[120%] sm:text-[10px] font-semibold text-gray-900_0 sm:text-[10%] text-nowrap  sm:text-wrap sm:self-stretch sm:w-[100%] sm:justify-center sm:items-center"
        >
          {standardText}
        </Heading>
      </div>
    </div>
  );
}
