import ImageGallery from "../../components/ImageGallery";
import React, { Suspense } from "react";
import Banner from "./regularBanner1.svg";
import Banner2 from "./regularBanner2.svg";
import Banner3 from "./regularBanner3.svg";
import Banner4 from "./regularBanner4.svg";
import Banner5 from "./regularBanner5.svg";
import Banner6 from "./regularBanner6.svg";
import Banner7 from "./banner2.svg";
import Banner8 from "./banner.svg";

const data = [
  {
    firstImage: Banner,
  },
  {
    firstImage: Banner2,
  },
  {
    firstImage: Banner3,
  },
  {
    firstImage: Banner4,
  },
  {
    firstImage: Banner5,
  },
  {
    firstImage: Banner6,
  },
  {
    firstImage: Banner7,
  },
  {
    firstImage: Banner8,
  },
];

export default function MoveinandmoveoutColumnimg0192on() {
  return (
    <div className="mt-[136px] self-stretch">
      <div className="flex  flex-wrap  gap-x-2  gap-y-3 w-[120%] sm:flex sm:flex-col justify-center items-center sm:gap-5 lg:flex lg:flex-row lg:flex-wrap  lg:gap-4 lg:gap-x-4 lg:justify-between sm:justify-center sm:w-[100%]">
        <Suspense fallback={<div>Loading feed...</div>}>
          {data.map((d, index) => (
            <ImageGallery firstImage={d.firstImage} />
          ))}
        </Suspense>
      </div>
    </div>
  );
}
