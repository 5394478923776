import { Text, Button, Heading, Img } from "../../components";
import FeatureList from "../../components/FeatureList";
import React from "react";
import CarolPhoto from "./CarolPhoto.svg";

export default function AboutusColumndesignsem() {
  return (
    <div className=" flex flex-col items-center gap-[22px] self-stretch pl-[58px] pr-14 sm:px-5 lg:max-w-full">
      <div className="mx-auto flex w-full  self-stretch  sm:justify-center">
        <div className="flex w-full items-center sm:flex-col md:flex-col md:items-start md:mt-28 text-center  lg:mt-40 lg:pt-0 lg:gap-20 lg:ml-[5%] lg:items-start lg:justify-center sm:ml-0">
          <div className="flex flex-col    sm:w-[100%] md:w-[100%] lg:mt-0 lg:pt-0 lg:w-[100%] lg:max-w-[500px] sm:items-center sm:justify-center">
            <Img
              src={CarolPhoto}
              alt="Designsemnome"
              className="sm:h-[100%] md:h-[100%] py-10 lg:pt-0 mt-5 w-[100%] rounded-[90px] object-contain sm:w-full top-0 lg:pb-5 lg:mt-0"
            />
            <Text
              size="textxl"
              as="p"
              className=" font-bevietnampro text-[24.89px] font-normal text-black-900_63  sm:text-[22px] text-center"
            >
              Founder, Carol Bishop
            </Text>
          </div>
          <div>
            <div className="flex  flex-col items-end gap-5 sm:self-stretch sm:gap-[31px] sm:items-center lg:items-start lg:gap-20 md:gap-8 lg:w-[100%] ">
              <div className="flex w-[88%] flex-col gap-7 sm:w-[90%] lg:w-[100%] lg:items-start sm:items-start">
                <div className="flex flex-col items-center  gap-2.5 py-10 mt-5 pb-0 lg:pt-0 lg:items-start lg:gap-12 md:gap-5">
                  <Heading
                    size=""
                    as="h1"
                    className=" font-bevietnampro text-[63px] font-semibold text-gray-900 sm:text-[200%] sm:text-center lg:text-[300%] lg:text-start"
                  >
                    Our Company!
                  </Heading>
                  <Text
                    size="textmd"
                    as="p"
                    className="ml-1.5 w-[74%] font-bevietnampro text-[80%] font-normal leading-[160%] text-gray-700 sm:ml-0 sm:w-full gap-0 md:text-left md:w-[60%] md:text-[120%] lg:text-left lg:w-[90%] lg:text-[120%] max-w-[500px]"
                  >
                    <>
                      520 Cleaning Solutions has been dedicated to providing a
                      clean, balanced, and welcoming environment for your
                      families. <br />
                      With seven years of experience, our mission is to allow
                      our clients to focus on precious moments with their loved
                      ones while we take care of the cleaning with love and
                      care.{" "}
                    </>
                  </Text>
                </div>
                <div className="flex gap-8 sm:flex-col md:gap-16 sm:items-center sm:justify-center sm:w-[100%] sm:gap-5">
                  <FeatureList className="w-[10%] lg:w-[50%]" />
                  <FeatureList
                    vettedText="Affordable Prices"
                    reliableText="Best Quality"
                    standardText="Dedicate care"
                    className="w-[20%] lg:w-[50%]"
                  />
                </div>
              </div>
              <a
                className="sm:flex"
                href="https://forms.gle/LNWMEL59EvRHchmH8"
                target="blank"
              >
                <Button className="mr-4 flex h-8 flex-row items-center justify-center rounded-[12px] bg-teal-300 px-5 text-center text-[100%] capitalize text-white-a700  sm:text-[100%]  growUpButton w-[100%] lg:mr-0 lg:text-[150%] lg:w-[120%] lg:px-0 lg:p-6 sm:mx-0 sm:px-12">
                  Join Our List of Satisfied Customers!
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
