import { Text } from "..";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Img } from "../../components";
import PortugueseLogo from "../../components/Header/Icons/img_ellipse_1.png";
import SpanishLogo from "../../components/LanguageIcons/SpanishIcon.svg";
import EnglishLogo from "../../components/LanguageIcons/EnglishLogo.svg";

const MenuFrequencyServices = ({
  children,
  className = "",
  Icon,
  selectedLanguage,
  setMenuLanguageIsOpen,
  setSelectedFrequency,
  ...restProps
}) => {
  const [actualGold, setActualGold] = React.useState("");
  const [showServices, setShowServices] = useState(false);

  useEffect(() => {}, []);

  function getPageYAfterScroll() {
    window.scrollTo(0, 0);
  }

  function handleClick(value) {
    setSelectedFrequency(value);
  }

  return (
    <div className="absolute  top-[93%] left-[36%] z-[99] ">
      <div className="w-[146.5%] rounded-[7.683px] bg-[#FFF] border-[1.2px] border-solid border-blue_gray-700 ">
        <div className="flex gap-[30px] w-full">
          <div className="flex flex-col items-start gap-4 sm:gap-4 servicesTypeText">
            <div className="flex flex-col items-start gap-0 sm:gap-3">
              <li
                name="Weekly Cleaning"
                onClick={(e) => handleClick("Weekly Cleaning")}
                href="#"
                className="flex w-[100%] text-md sm:text-[13px] m-0 p-0 mr-0 mt-2"
              >
                <h1
                  className={`flex w-[100%]  text-[100%] text-transparent p-3 pr-4 pl-8 textTypeServices text-md`}
                >
                  Weekly Cleaning
                </h1>
              </li>
              <li
                name="Bi-weekly Cleaning"
                onClick={(e) => handleClick("Bi-weekly Cleaning")}
                href="#"
                className="flex w-[140%] sm:text-[13px] m-0 p-0 mr-0"
              >
                <h1
                  className={`flex w-[100%]  text-[110%] text-transparent p-3 pr-4 pl-8 textTypeServices`}
                >
                  Bi-weekly Cleaning
                </h1>
              </li>
              <li
                name="Deep cleaning"
                onClick={(e) => handleClick("Monthly Cleaning")}
                href="#"
                className="flex w-[140%] sm:text-[13px] m-0 p-0 mr-0"
              >
                <h1
                  className={`w-[100%]  bg-clip-text text-[110%] text-transparent p-3 pr-4 pl-8 textTypeServices`}
                >
                  Monthly Cleaning
                </h1>
              </li>
              <li
                name="Move in cleaning"
                onClick={(e) => handleClick("One-Time Deep Cleaning")}
                href="#"
                className="flex w-[140%] sm:text-[13px] m-0 p-0 mr-0"
              >
                <h1
                  className={`w-[100%] bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 bg-clip-text text-[110%] text-transparent p-3 pr-4 pl-8 textTypeServices`}
                >
                  One-Time Deep Cleaning
                </h1>
              </li>
              <li
                value="Move out cleaning"
                name="Move out cleaning"
                onClick={(e) => handleClick("Seasonal Cleaning")}
                href="#"
                className="flex w-[140%] sm:text-[13px] m-0 p-0 mr-0"
              >
                <h1
                  className={`flex items-center w-[100%]  bg-clip-text text-[110%] text-transparent p-3 pr-4 pl-8  textTypeServices`}
                >
                  Seasonal Cleaning
                </h1>
              </li>
              <div className="flex mb-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MenuFrequencyServices.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  Icon: PropTypes.node,
  selectedLanguage: PropTypes.string,
  setSelectedFrequency: PropTypes.func,
};

export { MenuFrequencyServices };
