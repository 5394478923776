import { Img } from "./..";
import React from "react";

export default function ImageGallery({ firstImage }) {
  return (
    <div
      className={`lg:flex w-[24%] md:w-[23%] sm:w-[90%] justify-start sm:justify-center sm:items-center`}
    >
      <Img
        src={firstImage}
        alt="Img0192one"
        className=" flex-1 object-cover rounded-lg sm:flex   sm:self-center lg:flex max-h-[300px] md:max-h-[150px]"
      />
      {/* <Img
        src={secondImage}
        alt="Imagetwentyfive"
        className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[476px] w-full flex-1 object-cover"
      /> */}
    </div>
  );
}
